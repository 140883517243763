/* eslint-disable no-magic-numbers */
/* eslint-disable react/forbid-elements */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
    A11y, Navigation, Pagination, Scrollbar,
} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import CmsBlock from 'Component/CmsBlock';
import If from 'Component/If';
import { updateMeta } from 'Store/Meta/Meta.action';
import { PageMeta } from 'Store/Meta/Meta.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { getCurrentEnv, getUrlToSearch } from 'Util/Common/Util';

import {
    baseImageUrl,
    marchiBottom,
    marchiTop,
} from './StaticHomePage.config';
import { StaticHomePageComponentProps } from './StaticHomePage.type';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './StaticHomePage.style';

/** @namespace Pwa/Component/StaticHomePage/Component/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateMeta: (meta: Partial<PageMeta>) => dispatch(updateMeta(meta)),
});

/** @namespace Pwa/Component/StaticHomePage/Component/mapStateToProps */
export const mapStateToProps = (state: any) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/StaticHomePage/Component */
export class StaticHomePageComponent extends PureComponent<StaticHomePageComponentProps> {
    currentEnv = getCurrentEnv();

    interval: NodeJS.Timeout | undefined;

    componentDidMount() {
        this.interval = setInterval(() => {
            this.forceUpdate();
        // eslint-disable-next-line no-magic-numbers
        }, 1000);

        const { updateMeta } = this.props;

        updateMeta({ title: 'Motorparts', description: 'Homepage Motorparts' });
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    blockHomepageTop(): ReactElement {
        return (
            <div block="StaticHomePage" elem="ContainerBTop">
                <div block="StaticHomePage" elem="LeftSection">
                    <img src={ `${baseImageUrl}moto.png` } alt="moto" width="640" height="360" />
                    <div block="StaticHomePage" elem="Overlay">
                        <img src={ `${baseImageUrl}flag.png` } alt="Brand Logo" width="300" height="15" />
                        <h2>{ __('BlockHpTop.title') }</h2>
                        <p>{ __('BlockHpTop.desc') }</p>
                        <a href="/search/%20" rel="noopener noreferrer">
                            <button block="StaticHomePage" elem="Button">{ __('BlockHpTop.showProduct') }</button>
                        </a>
                    </div>
                </div>
                <div block="StaticHomePage" elem="RightSection">
                    <div>
                        <h3>{ __('BlockHpTop.ask') }</h3>
                        <p>{ __('BlockHpTop.askDesc') }</p>
                        <a href={ `mailto:${__('Footer.clientService.mail')}` }>{ __('BlockHpTop.contactUs') }</a>
                    </div>
                    <If condition={ !isSignedIn() }>
                        <div block="StaticHomePage" elem="RightSecond">
                            <h3>{ __('BlockHpTop.titleTwo') }</h3>
                            <p>{ __('BlockHpTop.askSignUp') }</p>
                            <a href="/customer/account/create">{ __('BlockHpTop.signUp') }</a>
                        </div>
                    </If>
                </div>
            </div>
        );
    }

    renderTagA(elem: any): ReactElement {
        const url = (this.currentEnv === 'stg') ? elem.url : elem.urlProd;

        return (
            <a href={ url } rel="noopener noreferrer">
                <img src={ elem.image } alt={ elem.alt } width="347" height="171" />
            </a>
        );
    }

    renderTagASwiper(elem: any): ReactElement {
        const url = (this.currentEnv === 'stg') ? elem.url : elem.urlProd;

        return (
            <SwiperSlide>
                <a block="StaticHomePage" elem="ItemSlider" href={ url } rel="noopener noreferrer">
                    <img src={ elem.image } alt={ elem.alt } width="95" height="50" />
                </a>
            </SwiperSlide>
        );
    }

    blockHomepageMarche(): ReactElement {
        const { device: { isMobile } } = this.props;

        return (
            <>
                <div block="StaticHomePage" elem="ContainerMarche">
                    <div block="StaticHomePage" elem="ContainerRight">
                        <div block="StaticHomePage" elem="TextBox">
                            <h1>{ __('BlockHpMarche.title') }</h1>
                            <p>{ __('BlockHpMarche.desc') }</p>
                        </div>
                    </div>
                    <div block="StaticHomePage" elem="ImageGrid">
                        { marchiTop.map((elem) => this.renderTagA(elem)) }
                    </div>
                </div>
                <div block="StaticHomePage" elem="ImageList">
                    <Swiper
                      modules={ [Navigation, Pagination, Scrollbar, A11y] }
                      spaceBetween={ isMobile ? 15 : 30 }
                      slidesPerView={ isMobile ? 4 : 9 }
                      navigation
                    >
                        { marchiBottom.map((elem) => this.renderTagASwiper(elem)) }
                    </Swiper>
                </div>
            </>
        );
    }

    blockHomepagePromo(): ReactElement {
        return (
            <section block="StaticHomePage" elem="PromoSection">
                <img src={ `${baseImageUrl}moto2.png` } alt="" width="1636" height="544" />
                <div block="StaticHomePage" elem="PromoText">
                    <div block="StaticHomePage" elem="PromoLabel">{ __('BlockHpPromo.subTitle') }</div>
                    <h2>{ __('BlockHpPromo.title') }</h2>
                    <p>{ __('BlockHpPromo.desc') }</p>
                </div>
            </section>
        );
    }

    blockHomepageOfferte(): ReactElement {
        const urlTprfactory = getUrlToSearch('794248', '80', this.currentEnv);

        return (
            <div block="StaticHomePage" elem="Container">
                <div block="StaticHomePage" elem="Card">
                    <img src={ `${baseImageUrl}homepage-promo1.png` } alt="" width="766" height="561" />
                    <div block="StaticHomePage" elem="CardText">
                        <img src={ `${baseImageUrl}flag.png` } alt="" width="300" height="15" />
                        <h3>{ __('BlockHpOfferte.titleBoxOne') }</h3>
                        <a href={ urlTprfactory } rel="noopener noreferrer">
                            <button>{ __('BlockHpTop.showProduct') }</button>
                        </a>
                    </div>
                </div>
                <div block="StaticHomePage" elem="Card">
                    <img src={ `${baseImageUrl}homepage-promo2.png` } alt="" width="766" height="561" />
                    <div block="StaticHomePage" elem="CardText">
                        <img src={ `${baseImageUrl}flag.png` } alt="" width="300" height="15" />
                        <p>{ __('BlockHpOfferte.subTitleBoxTwo') }</p>
                        <h3>{ __('BlockHpOfferte.titleBoxTwo') }</h3>
                        <a href="/offerte-settimana.html" rel="noopener noreferrer">
                            <button>{ __('BlockHpTop.showProduct') }</button>
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    blockHomepageProductPromo(): ReactElement {
        const urlMph = getUrlToSearch('794221', '53', this.currentEnv);

        return (
            <div block="StaticHomePage" elem="ContainerSpecialPromo">
                <div block="StaticHomePage" elem="PromoBanner">
                    <h1>{ __('BlockHpProductPromo.title') }</h1>
                    <p>{ __('BlockHpProductPromo.desc') }</p>
                    <a
                      href={ urlMph }
                      rel="noopener noreferrer"
                      block="StaticHomePage"
                      elem="Button"
                    >
                        { __('BlockHpTop.showProduct') }
                    </a>
                    <div block="StaticHomePage" elem="Swoosh" />
                </div>
                <div block="StaticHomePage" elem="CarouselProduct">
                    <CmsBlock identifier="homepage-widget-products" />
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <meta title="Motorparts" />
                <div block="StaticHomePage">
                    <div block="CmsPage-Wrapper CmsPage-Wrapper_page_width_default">
                        { this.blockHomepageTop() }
                        { this.blockHomepageMarche() }
                        <CmsBlock identifier="homepage-prodotti-primo-piano" />
                        { /* <If condition={ isSignedIn() }>
                            { this.blockHomepagePromo() }
                            { this.blockHomepageProductPromo() }
                        </If> */ }
                        { /* { this.blockHomepageOfferte() } */ }
                    </div>
                </div>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticHomePageComponent);
